::-webkit-scrollbar {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  display: block;
  width: var(--scrollbar-width);
}

.scrollbar::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
  border-radius: 100px;
}

html {
  scroll-behavior: smooth;
  cursor:
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' width='32' height='32'><circle r='50' cx='50' cy='50' /></svg>")
      16 16,
    auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'IBM Plex Mono';
  font-weight: 300;
}

/* fonts */

@font-face {
  src: url('./fonts/ibm-plex-mono.ttf');
  font-family: 'IBM Plex Mono';
}

@font-face {
  src: url('./fonts/px.ttf');
  font-family: 'Px';
}

@font-face {
  src: url('./fonts/terminal-grotesque_open.otf');
  font-family: 'Terminal Open';
}

@font-face {
  src: url('./fonts/terminal-grotesque.ttf');
  font-family: 'Terminal';
}

/* svg icons */

.colors-icon {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 135 135'><g fill='none' stroke='black' stroke-width='5.5'><circle cx='65' cy='66' r='54'/><circle cx='65' cy='66' r='19.5'/><path d='M65.5 12v34M65.5 86.5v34M119.75 66.25h-34M45.25 66.25h-34M103.86 104.61L79.82 80.57M51.18 51.93L27.14 27.89M27.14 104.61l24.04-24.04M79.82 51.93l24.04-24.04'/></g></svg>");
}

.theme-icon {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 130 130'><g fill='none' stroke='black' stroke-width='6'><path d='M19 19h92v92H19z'/><circle cx='65' cy='65' r='25'/></g></svg>");
}

.grid-icon {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 130 130'><path fill='none' stroke='black' stroke-width='6' d='M19 19h92v92H19zM49.67 19.5v92M80.33 19.5v92M111 50.17H19M111 80.83H19'/></svg>");
}

.coordinates-icon {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 130 130'><g fill='none' stroke='black' stroke-width='6'><path d='M65 10v110M120 65H10' /><circle cx='65' cy='65' r='28.05' /></g></svg>");
}

.arrow-icon {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 130 130'><path fill='none' stroke='black' stroke-width='5' d='M57.15 112.9L9.5 65.25 57.15 17.6M124.5 65.25H10.16'/></svg>");
}

.immersive-mode-on-icon {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 130 130'><path fill='none' stroke='black' stroke-width='5' d='M12 51V12h39M12 79v39h39M118 51V12H79M118 79v39H79'/></svg>");
}

.immersive-mode-off-icon {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 130 130'><path fill='none' stroke='black' stroke-width='5' d='M39 0v39H0M39 130V91H0M91 0v39h39M91 130V91h39'/></svg>");
}

/* animations */

.fade-in {
  animation: 0.25s fade-in linear;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* specific classes */

.hover_bb {
  border-bottom: solid 2px transparent;
}

.hover_bb:hover {
  border-bottom: solid 2px black;
}

.network {
  border: solid 1px var(--color);
}

.network .separator {
  background: var(--color);
}

.network * {
  color: var(--color);
}

.network:hover {
  background: var(--color);
}

.network:hover * {
  color: var(--grey8);
}

.network:hover .separator {
  background: var(--grey8);
}

.project-title {
  background: var(--background-color);
  color: var(--text-color);
}

.project-title:hover {
  background: var(--hover-background-color);
  color: var(--hover-text-color);
}

#grid-visuals {
  grid-template-columns: 1fr 1fr;
  column-gap: 80px;
  row-gap: 80px;
}

@media (max-width: 1200px) {
  #grid-visuals {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  #grid-visuals > * {
    margin-top: 0;
  }
}
